import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import ContactUsImage from "images/rps-contact-us.jpg";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import AppForm from "./AppForm";

const TwoColumn = tw(
  ContentWithPaddingXl
)`flex flex-col md:flex-row justify-between py-20 md:py-24 lg:mb-16`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-1/2 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-1/2 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-right-top h-full`,
]);
const TextContent = tw.div`lg:py-9 text-center md:text-left`;
const Subheading = tw(SubheadingBase)`text-center text-lg md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-8 text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight max-w-38r`;
const Description = tw.p`mt-8 text-center md:text-left text-sm md:text-base lg:text-xl font-medium leading-relaxed text-gray-600 max-w-38r whitespace-pre-line`;

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to get in touch with us.</>,
  description = `
                  The Heron: (020) - 7638 - 6668
                  Monday - Friday: 12.00 - 15.00 & 17.00 - 22.00
                  Saturday: 17.30 - 22.00
                  Email: theheron@phamsushi.com

                  Takeaway and Deliveroo: (020) - 7638 - 6668
                  Monday - Friday: 12.00 -  22.00
                  Saturday: 17.00 -22.00
                  Email: phamsushi@aol.com
                `,
  submitButtonText = "Contact Us",
  textOnLeft = true,
  imageSrc = ContactUsImage,
  formCss = null,
  imageCss = null,
  fields = [
    {
      type: "email",
      name: "email",
      placeholder: "Your Email Address",
    },
    {
      type: "text",
      name: "name",
      placeholder: "Full Name",
    },
    {
      type: "text",
      name: "subject",
      placeholder: "Subject",
    },
    {
      type: "textarea",
      name: "message",
      placeholder: "Your Message Here",
    },
  ],
  onSubmit = (value) => {
    console.log("form submit", value);
  },
  ...formProps
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image css={imageCss} imageSrc={imageSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <AppForm
              fields={fields}
              onSubmit={onSubmit}
              submitButtonText={submitButtonText}
              formCss={formCss}
              {...formProps}
            />
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
