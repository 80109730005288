import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { LinkButton } from "components/misc/Buttons.js";
import SubGrid from "./SubGrid";

const ContentWithPadding = tw(ContentWithPaddingXl)`py-12 lg:py-16 mb-10 lg:mb-64`;
const HeaderRow = tw.div`flex justify-between items-center flex-col`;
const Header = styled(SectionHeading)`
  ${css`font-family: "Cezanne";`}
  ${tw`font-normal text-white text-4xl lg:text-8xl leading-tight`}
`;
const SubHeader = styled(SectionHeading)`
  ${tw`font-normal text-white text-2xl lg:text-2xl leading-tight`}
`;
const SubHeaderAlt = styled(SectionHeading)`
  ${tw`font-bold text-white text-2xl lg:text-2xl leading-tight`}
`;
const TabsControl = tw.div`flex flex-wrap bg-gray-800 px-2 py-2 rounded leading-none mt-12`;
const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-600 text-gray-700`}
  }
  ${props => props.active && tw`bg-gray-700! text-white!`}
  }
`;
const TabContent = tw(motion.div)`mt-10 sm:-mr-10 md:-mr-6 lg:-mr-12 max-w-full`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.div)`rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 bg-center bg-no-repeat relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end text-secondary-500`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;
const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(LinkButton)`text-sm`;
const CardReview = tw.div`font-medium text-xs text-gray-900`;
const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold text-white group-hover:text-gray-100`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-500`;
const CardPrice = tw.p`mt-4 text-xl font-bold text-gray-600`;


export default ({
  heading = "Checkout the Menu",
  subHeading = "",
  subHeadingAlt = "",
  tabs = {},
  showPrice = false
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPadding>
        <HeaderRow>
          <Header>{heading}</Header>
          <SubHeader>{subHeading}</SubHeader>
          <SubHeaderAlt>{subHeadingAlt}</SubHeaderAlt>
          {tabsKeys && tabsKeys.length > 0 &&
            <TabsControl>
              {tabsKeys.map((tabName, index) => (
                <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                  {tabName}
                </TabControl>
              ))}
            </TabsControl>
          }
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale: 1,
                display: "block",
              },
              hidden: {
                opacity: 0,
                scale: 0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            <SubGrid data={tabs[tabKey]} showPrice={showPrice}/>
          </TabContent>
        ))}
      </ContentWithPadding>
    </Container>
  );
};

